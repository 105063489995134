import styled from 'styled-components'

import { Container, Title } from './index'

export const PolicyContainer = styled(Container)`
  padding: 30px 20px 40px;
  ${Title} {
    margin-bottom: 20px;
    text-align: center;
  }

  .legal {
    font-family: quasimoda, sans-serif;
    max-width: 600px;
    margin: 60px auto;
  }

  h2 {
    font-size: 2rem;
  }
`
