import React from 'react'

import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import { SEO } from '../components'
import { Title } from '../styled'
import { PolicyContainer } from '../styled/legal'

const ProductPage = ({ data, path }: any) => {
  const { t } = useTranslation()
  const policy = data.shopifyShopPolicy
  return (
    <>
      <SEO title={t(policy.type, { defaultValue: policy.title })} path={path} />
      <PolicyContainer>
        <Title>{t(policy.type, { defaultValue: policy.title })}</Title>
        <div
          className="legal"
          dangerouslySetInnerHTML={{ __html: policy.body }}
        />
      </PolicyContainer>
    </>
  )
}

export const query = graphql`
  query ($handle: String!) {
    shopifyShopPolicy(handle: { eq: $handle }) {
      id
      type
      title
      body
    }
  }
`

export default ProductPage
